import { Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import toast from 'react-hot-toast';
import useLocales from '#/lib/hooks/useLocales';
import { useSubmitLatestTax } from '#/lib/api/partnerQueries';

export default function SubmitTax() {
  const { translate } = useLocales();
  const { mutateAsync: submitLatestTax, isLoading } = useSubmitLatestTax();

  const handleSubmitLatestTax = async () => {
    try {
      await submitLatestTax({ consultant_id: 1 });
      toast.success(translate('toast_notifications.success.submitLatestTax'));
    } catch (error) {
      toast.error(translate('toast_notifications.error.submitLatestTax'));
    }
  };

  return (
    <LoadingButton
      variant="outlined"
      onClick={handleSubmitLatestTax}
      loading={isLoading}
    >
      <Typography>{translate('global.taxFolder.submitLatestTax')}</Typography>
    </LoadingButton>
  );
}
