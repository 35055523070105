import { Typography } from '@mui/material';
import { S } from './styles';
import useLocales from '#/lib/hooks/useLocales';

export default function ListHead() {
  const { translate } = useLocales();
  return (
    <S.RowWrapper sx={{ backgroundColor: 'action.hover' }}>
      <S.RowCell />
      <S.RowCell>
        <Typography>
          {translate('global.taxFolder.findTaxExpert.tableHead.expert')}
        </Typography>
      </S.RowCell>
      {/* <S.RowCell width="20%">
        <Typography>
          {translate('global.taxFolder.findTaxExpert.tableHead.location')}
        </Typography>
      </S.RowCell> */}
      {/* <S.RowCell width="25%">
        <Typography>
          {translate('global.taxFolder.findTaxExpert.tableHead.price')}
        </Typography>
      </S.RowCell> */}
    </S.RowWrapper>
  );
}
