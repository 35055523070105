import { Box, Stack, styled } from '@mui/material';

export const S = {
  RowWrapper: styled(Box)<{ bgColor?: boolean }>`
    display: flex;
    align-items: center;
    padding: 8px 20px;
    background-color: ${({ bgColor, theme }) =>
      bgColor ? theme.palette.action.selected : 'transparent'};
  `,
  RowCell: styled(Stack)<{ width?: string }>`
    width: ${({ width }) => width};
  `,
};
