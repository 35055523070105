import { Button, Paper, Stack, Typography } from '@mui/material';
import { useCallback, useState } from 'react';
import DialogAnimate from '#/components/shared/animate/DialogAnimate';
import useLocales from '#/lib/hooks/useLocales';
import ListToolbar from './list-toolbar';
import ListHead from './list-head';
import ListItem from './list-item';
import cityData from '../../Admin/consulting-legal-table/AddConsultant/cityData';
import {
  IConsultantByTag,
  useGetConsultantByTag,
  useHireTaxExpert,
} from '#/lib/api/partnerQueries';
import { invalidateSuccessorQueries } from '#/lib/api/successor';

type Props = {
  open: boolean;
  onClose: () => void;
};

const defaultFilters = {
  advisor_name: '',
  location: [],
  search: '',
};

const locations = cityData.map((city) => city.label);

export default function ExpertsModal({ open, onClose }: Props) {
  const [filters, setFilters] = useState(defaultFilters);
  const [selectedExpert, setSelectedExpert] = useState<IConsultantByTag | null>(
    null
  );

  const { translate } = useLocales();

  const { data: taxExperts } = useGetConsultantByTag('tax', !!open);
  const { mutateAsync: hireTaxExpert } = useHireTaxExpert();

  const onSelect = (expert: IConsultantByTag) => {
    setSelectedExpert(selectedExpert?.id === expert.id ? null : expert);
  };

  const onHireTaxExpert = async () => {
    try {
      await hireTaxExpert({ consultant_id: selectedExpert?.id ?? 0 });
      invalidateSuccessorQueries.getInvitationsList();
      onClose();
    } catch (error) {
      console.log(error);
    }
  };

  const handleFilters = useCallback(
    (name: string, value: string | string[]) => {
      setFilters((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    },
    []
  );

  return (
    <DialogAnimate open={open} onClose={onClose}>
      <Paper sx={{ py: 2 }}>
        <Stack sx={{ px: 2 }}>
          <Typography variant="subtitle1">
            {translate('global.taxFolder.findTaxExpert.title')}
          </Typography>
          <Typography variant="body2">
            {translate('global.taxFolder.findTaxExpert.description')}
          </Typography>
        </Stack>

        <Stack sx={{ px: 2 }}>
          <ListToolbar
            filters={filters}
            onFilters={handleFilters}
            locationsOptions={locations}
          />
        </Stack>
        <ListHead />
        {taxExperts?.map((expert) => (
          <ListItem
            key={expert.id}
            expert={expert}
            onSelect={onSelect}
            selectedExpert={selectedExpert}
          />
        ))}

        <Stack
          direction="row"
          alignItems="center"
          justifyContent="flex-end"
          sx={{ mt: 2, px: 2 }}
          spacing={2}
        >
          <Button variant="outlined" onClick={onClose}>
            <Typography>{translate('global.cancel')}</Typography>
          </Button>
          <Button variant="contained" onClick={onHireTaxExpert}>
            <Typography>{translate('global.hire')}</Typography>
          </Button>
        </Stack>
      </Paper>
    </DialogAnimate>
  );
}
