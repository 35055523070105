import { Avatar, Checkbox, Typography } from '@mui/material';
import { S } from './styles';
import { IConsultantByTag } from '#/lib/api/partnerQueries';

type ListItemProps = {
  expert: IConsultantByTag;
  onSelect: (expert: IConsultantByTag) => void;
  selectedExpert: IConsultantByTag | null;
};

export default function ListItem({
  expert,
  onSelect,
  selectedExpert,
}: ListItemProps) {
  return (
    <S.RowWrapper bgColor={expert.id === selectedExpert?.id}>
      <S.RowCell direction="row" alignItems="center" spacing={2} width="60%">
        <Checkbox
          checked={expert.id === selectedExpert?.id}
          onChange={() => onSelect(expert)}
        />
        <Avatar />
        <Typography>
          {expert.first_name} {expert.last_name}
        </Typography>
      </S.RowCell>
      {/* <S.RowCell width="20%">
        <Typography>Zürich</Typography>
      </S.RowCell> */}
      {/* <S.RowCell width="20%">
        <Typography>CHF 250</Typography>
      </S.RowCell> */}
    </S.RowWrapper>
  );
}
