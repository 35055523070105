import {
  Box,
  Button,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useCallback, useEffect, useState } from 'react';
import { format } from 'date-fns';
import DashboardContainer from '#/components/shared/dashboard-container';
import useLocales from '#/lib/hooks/useLocales';
import Filters from '../DFPhotosVideos/filters';
import useBoolean from '#/lib/hooks/useBoolean';
import { IFileFilters, IFileFilterValue } from '#/types/file';
import useTable from '#/lib/hooks/useTable';
import { isAfter } from '#/utils/formatTime';
import Iconify from '#/components/shared/ui/Iconify';
import TableView from '../DFPhotosVideos/table/table-view';
import GridView from '../DFPhotosVideos/grid/grid-view';
import useLocalStorage from '#/lib/hooks/useLocalStorage';
import useAccountSwitch from '#/lib/hooks/useAccountSwitch';
import useAuth from '#/lib/hooks/useAuth';
import { DndProvider } from '#/components/shared/dnd/dnd-provider';
import CustomBreadcrumbs from '#/components/shared/custom-breadcrumbs/custom-breadcrumbs';
import ConfirmDialog from '#/components/shared/confirm-dialog';
import {
  invalidateDigitalAssetsQueries,
  useBulkDeleteFiles,
  useBulkDeleteFolders,
  useGetFiles,
  useMoveFile,
  useMoveFolder,
} from '#/lib/api/digitalAssets';
import SubmitTax from './submit-tax';
import ExpertsModal from './experts';

const defaultFilters: IFileFilters = {
  name: '',
  type: [],
  startDate: null,
  endDate: null,
};

export const FILE_TYPE_OPTIONS = [
  'audio',
  'image',
  'video',
  'document',
  'archive',
];

const PARENT_LIST_KEY = 'taxFolderParentList';

export default function TaxFolderClient() {
  const [parentList, setParentList] = useState<(string | number | any)[]>([]);
  const [view, setView] = useState<string>('list');
  const [fileView, setFileView] = useLocalStorage('taxFolderFileView', 'list');
  const [filters, setFilters] = useState(defaultFilters);
  const [parentListKey, setParentListKey] = useLocalStorage(
    PARENT_LIST_KEY,
    []
  );
  const [guestOn] = useLocalStorage('guest_on', false);

  const { user } = useAuth();
  const { currentAccount } = useAccountSwitch();
  const { translate } = useLocales();
  const navigate = useNavigate();

  const confirm = useBoolean();
  const openDateRange = useBoolean();
  const openModal = useBoolean();

  const hasPartnership = !!user?.partner;

  const table = useTable({ defaultRowsPerPage: 10 });

  const { mutateAsync: moveFile } = useMoveFile();
  const { mutateAsync: moveFolder } = useMoveFolder();
  const { mutateAsync: onBulkFolderDelete } = useBulkDeleteFolders();
  const { mutateAsync: onBulkFileDelete } = useBulkDeleteFiles();

  const { data: files } = useGetFiles(
    !!user,
    filters?.name,
    parentList.map((item) => item.id),
    filters?.startDate &&
      format(new Date(filters?.startDate as Date), 'yyyy-MM-dd'),
    filters?.endDate &&
      format(new Date(filters?.endDate as Date), 'yyyy-MM-dd'),
    filters?.type,
    'tax'
  );

  const dateError = isAfter(filters.startDate, filters.endDate);
  const notFound = !files?.folders?.length && !files?.files?.length;

  const handleChangeView = useCallback(
    (event: React.MouseEvent<HTMLElement>, newView: string | null) => {
      if (newView !== null) {
        setView(newView);
        setFileView(newView);
      }
    },
    []
  );

  const handleFilters = useCallback(
    (name: string, value: IFileFilterValue) => {
      table.onResetPage();
      setFilters((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    },
    [table]
  );

  const handleParentList = useCallback((id: string | number, name: string) => {
    setParentList((prevState) => {
      const existingIndex = prevState.findIndex((item) => item.id === id);
      if (existingIndex > -1) {
        return prevState.filter((_, index) => index !== existingIndex);
      }
      return [...prevState, { id, name }];
    });
  }, []);

  const handleOpenFile = useCallback((filepath: string) => {
    window.open(filepath, '_blank');
  }, []);

  const handleParentNavigation = useCallback((id?: string | number) => {
    setParentList((prevState) => {
      if (!id) {
        return [];
      }

      const index = prevState.findIndex((item) => item.id === id);

      return index > -1 ? prevState.slice(0, index + 1) : prevState;
    });
  }, []);

  const handleBulkDelte = async (ids: string[]) => {
    try {
      const hasFolders = files?.folders.filter((folder) =>
        ids.includes(String(folder.id))
      );
      const hasFiles = files?.files.filter((file) =>
        ids.includes(String(file.id))
      );

      if (hasFolders?.length && hasFiles?.length) {
        const folderIds = hasFolders?.map((folder) => folder.id);
        const fileIds = hasFiles?.map((file) => file.id);
        await onBulkFolderDelete({ folder_ids: folderIds, service: 'tax' });
        await onBulkFileDelete({ file_ids: fileIds, service: 'tax' });
      } else if (hasFolders?.length) {
        const folderIds = hasFolders?.map((folder) => folder.id);
        await onBulkFolderDelete({ folder_ids: folderIds, service: 'tax' });
      } else {
        await onBulkFileDelete({ file_ids: ids, service: 'tax' });
      }

      invalidateDigitalAssetsQueries.getFiles();
    } catch (error) {
      console.error('Error deleting folder: ');
    }
  };

  const handleBackToDashboard = () => {
    navigate('/dashboard/home');
  };

  const handleRedirectToChat = () => {
    navigate('/dashboard/chat?id=123123');
  };

  useEffect(() => {
    if (parentListKey) {
      setParentList(parentListKey);
    }
    if (fileView) {
      setView(fileView);
    }
  }, []);

  useEffect(() => {
    setParentListKey(parentList);
  }, [parentList]);

  return (
    <Box>
      <Stack direction="row" justifyContent="center">
        <Button onClick={handleBackToDashboard} sx={{ width: 'fit-content' }}>
          <Typography>{String(translate('global.back'))}</Typography>
        </Button>
      </Stack>
      <DashboardContainer
        introTitle={String(
          translate(
            guestOn ? 'global.guestTaxFolder.title' : 'global.taxFolder.title',
            {
              user: `${currentAccount?.first_name} ${currentAccount?.last_name}`,
            }
          )
        )}
        introDescription={
          guestOn
            ? String(translate('global.guestTaxFolder.description'))
            : String(translate('global.taxFolder.description'))
        }
      >
        {hasPartnership && (
          <Stack direction="row" justifyContent="center">
            {files?.hired_consultant ? (
              <SubmitTax />
            ) : (
              <Button variant="outlined" onClick={openModal.onTrue}>
                <Typography>
                  {translate('global.taxFolder.requestTaxExpert')}
                </Typography>
              </Button>
            )}
          </Stack>
        )}
        {guestOn && (
          <Stack direction="row" justifyContent="center">
            <Button
              onClick={handleRedirectToChat}
              sx={{ width: 'fit-content' }}
            >
              <Typography>
                {String(translate('global.guestTaxFolder.messageClient'))}
              </Typography>
            </Button>
          </Stack>
        )}
        <Stack
          spacing={2.5}
          sx={{
            my: { xs: 3, md: 5 },
          }}
        >
          <Stack
            spacing={2}
            direction={{ xs: 'column', md: 'row' }}
            alignItems={{ xs: 'flex-end', md: 'center' }}
          >
            <Filters
              openDateRange={openDateRange.value}
              onCloseDateRange={openDateRange.onFalse}
              onOpenDateRange={openDateRange.onTrue}
              filters={filters}
              onFilters={handleFilters}
              typeOptions={FILE_TYPE_OPTIONS}
              dateError={dateError}
            />
            <ToggleButtonGroup
              size="small"
              value={view}
              exclusive
              onChange={handleChangeView}
            >
              <ToggleButton value="list">
                <Iconify icon="solar:list-bold" />
              </ToggleButton>

              <ToggleButton value="grid">
                <Iconify icon="mingcute:dot-grid-fill" />
              </ToggleButton>
            </ToggleButtonGroup>
          </Stack>
        </Stack>

        <DndProvider
          moveFile={moveFile}
          moveFolder={moveFolder}
          onDragEndRefresh={invalidateDigitalAssetsQueries.getFiles}
        >
          <Stack
            sx={{
              my: 3,
            }}
          >
            {parentList.length > 0 && (
              <CustomBreadcrumbs
                links={[
                  {
                    name: translate('global.taxFolder.title'),
                    action: () => handleParentNavigation(),
                    id: null,
                  },
                  ...(parentList &&
                    parentList.map((item) => ({
                      name: item.name,
                      action: () => handleParentNavigation(item.id),
                      id: item.id,
                    }))),
                ]}
              />
            )}
          </Stack>

          {view === 'list' ? (
            <TableView
              table={table}
              dataFiltered={files}
              openFolder={handleParentList}
              openFile={handleOpenFile}
              parentList={parentList}
              notFound={notFound}
              onOpenConfirm={confirm.onTrue}
              service="tax"
            />
          ) : (
            <GridView
              table={table}
              dataFiltered={files}
              openFolder={handleParentList}
              openFile={handleOpenFile}
              parentList={parentList}
              notFound={notFound}
              service="tax"
            />
          )}
        </DndProvider>

        <ConfirmDialog
          open={confirm.value}
          onClose={confirm.onFalse}
          title={translate('global.deleteConfirmation.title')}
          content={
            <>
              {translate(
                `global.deleteConfirmation.${
                  table.selected.length > 1
                    ? 'multipleItemsQuestion'
                    : 'oneItemQuestion'
                }`,
                {
                  items: table.selected.length,
                  itemNr: table.selected.length,
                }
              )}
            </>
          }
          action={
            <Button
              variant="contained"
              color="error"
              onClick={() => {
                handleBulkDelte(table.selected);
                confirm.onFalse();
                table.setSelected([]);
              }}
            >
              <Typography color="inherit">
                {translate('global.delete')}
              </Typography>
            </Button>
          }
        />
      </DashboardContainer>
      <ExpertsModal open={openModal.value} onClose={openModal.onFalse} />
    </Box>
  );
}
